interface Props {
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchedPackages: any;
}

const PackageSearchItem = (props: Props) => {
  // eslint-disable-next-line
  const { searchedPackages } = props;

  return null;
};

export default PackageSearchItem;
